import styles from './EnergieShareStatus.module.scss'
import { useGetEnergyGardenBySlugQuery } from 'store/api/boltApi'
import { useRouter } from 'next/router'
import EnergyShareStatusLoader from 'features/energy-gardens/landingpage/components/energy-share-status/EnergyShareStatus.loader'
import { formatAmount } from 'utils/format'
import { Icon, ProgressIndicator } from '@boltenergy-be/design-system'
import { EnergyGardens } from 'features/energy-gardens/types'
import { determineEnergyGardenPhaseData } from 'features/energy-gardens/landingpage/utils'

const EnergyShareStatus = () => {
  // Router
  const { query } = useRouter()
  const { slug } = query

  // RTQuery
  const { data, isLoading } = useGetEnergyGardenBySlugQuery(slug as string)

  // Constants
  const { products = [] } = data || {}
  const solarPanels = products.find((product) => product.name === 'solar panels')
  const { totalQuantity = 0, available = 0, pricePerUnit = 0 } = solarPanels || {}
  const panelsSold = totalQuantity - available
  const totalSales = panelsSold * pricePerUnit
  const { availableInPhase, currentPhase, percentage } = determineEnergyGardenPhaseData(EnergyGardens.BRUGSE_ZONNETUIN, panelsSold)

  return (
    <div className={styles.root}>
      {isLoading ? (
        <EnergyShareStatusLoader />
      ) : (
        <>
          <ul className={styles.list}>
            {/* AVAILABLE */}
            <li className={styles['list-item']}>
              <Icon name="solarPanel" />
              <span className={styles.text}>
                Nog <strong>{formatAmount(availableInPhase)}</strong> zonne<span className={styles['hide-on-mobile']}>-</span>
                <br className={styles['hide-on-mobile']} />
                panelen beschikbaar
              </span>
            </li>

            {/* AMOUNT OF SALES */}
            <li className={styles['list-item']}>
              <Icon name="euro" />
              <span className={styles.text}>
                Al <strong>&euro; {formatAmount(totalSales)}</strong> <br className={styles['hide-on-mobile']} />
                opgehaald
              </span>
            </li>

            {/* WHERE AVAILABLE */}
            <li className={styles['list-item']}>
              <Icon name="peopleHeart" />
              <span className={styles.text}>
                Nu beschikbaar in <strong>heel Vlaanderen</strong>
              </span>
            </li>
          </ul>

          {currentPhase > 1 && (
            <ProgressIndicator
              id="new-phase-indicator"
              className={styles.progress}
              label={`Fase 1${currentPhase > 2 ? ' + 2' : ''}${currentPhase > 3 ? ' + 3' : ''}${panelsSold >= available ? ' + 4' : ''} (Uitverkocht)`}
              progress={100}
            />
          )}

          {panelsSold <= available && (
            <ProgressIndicator id="available-panels" className={styles.progress} label={`Fase ${currentPhase}`} progress={percentage} />
          )}
        </>
      )}
    </div>
  )
}

export default EnergyShareStatus
